<template>
  <v-container
    transition="scroll-y-reverse-transition"
    fluid
    :class="`${
      !$route.fullPath.includes('/perfil') && isReply ? 'isReply' : ''
    }`"
  >
    <v-row class="ma-0 pa-2">
      <v-col cols="auto" class="pa-1">
        <v-avatar
          v-if="!noLink"
          class="pointer"
          @click.prevent="$router.push(`/perfil/${id_usuario}`)"
        >
          <v-img :src="avatar" alt="avatar_usuario"></v-img>
        </v-avatar>
        <v-avatar v-else class="pointer">
          <v-img :src="avatar" alt="avatar_usuario"></v-img>
        </v-avatar>
      </v-col>
      <v-col class="pa-1">
        <v-row align="center" no-gutters>
          <v-col cols="auto">
            <span
              class="pa-1 pointer text-subtitle-1 font-weight-bold d-inline"
              @click.prevent="
                !noLink
                  ? $router.push(`/perfil/${id_usuario}`)
                  : $router.push(`/lugar/${id_lugar}`)
              "
              >{{ !$route.fullPath.includes("/perfil") ? nombre : lnombre }}
            </span>
          </v-col>
          <v-col cols="auto">
            <v-icon
              small
              v-if="id_usuario == $store.getters.getUserId"
              color="primary"
              >mdi-account</v-icon
            >
          </v-col>
          <v-col cols="auto">
            <small class="d-inline text--primary" v-if="fecha">{{
              fecha.split(" ")[0]
            }}</small>
          </v-col>
          <v-col cols="auto">
            <small
              class="d-inline text--primary"
              v-if="$route.fullPath.includes('/perfil') && isReply"
              ><v-icon>mdi-circle-small</v-icon>Respuesta</small
            >
          </v-col>
          <v-btn class="ml-2" icon v-if="controls" @click.stop="deleteComment">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-row>
        <v-row v-if="!!comment">
          <v-col>
            <v-card-text class="pa-1 mt-n3">{{ comment }}</v-card-text>
          </v-col>
        </v-row>
        <v-dialog
          v-model="img_big"
          width="unset"
          v-if="img_src != null"
          content-class="overflow_visible"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-img
              v-on="on"
              v-bind="attrs"
              :src="imagen"
              alt="imagen_comentario"
              max-width="250"
              contain
              max-height="250"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </template>
          <div style="position: relative">
            <v-btn
              @click.stop="img_big = false"
              small
              fab
              absolute
              top
              right
              color="white"
              ><v-icon color="primary">mdi-close</v-icon></v-btn
            >
            <img
              :src="imagen"
              alt="imagen_comentario"
              contain
              style="
                max-height: 85vh;
                width: auto;
                height: auto;
                max-width: 100%;
              "
            />
          </div>
        </v-dialog>

        <template v-if="!noReplyControls">
          <v-btn
            text
            color="primary"
            small
            @click="
              reply.shown = !reply.shown;
              reply.text = '';
            "
          >
            <span v-if="reply.shown === false" class="d-flex align-center">
              <v-icon>mdi-reply</v-icon>Responder
            </span>
            <span v-if="reply.shown === true" class="d-flex align-center">
              <v-icon>mdi-close-circle-outline</v-icon>Cancelar
            </span>
          </v-btn>

          <v-row v-if="reply.shown">
            <v-col>
              <v-form
                ref="reply"
                @submit.prevent="sendReply"
                style="width: 100%"
              >
                <v-textarea
                  full-width
                  dense
                  filled
                  no-resize
                  rows="1"
                  name="reply"
                  v-model="reply.text"
                  label="Escribe aquí tu respuesta"
                  :rules="[reply.rules.req]"
                ></v-textarea>
                <v-btn type="submit" color="primary" small>Enviar</v-btn>
              </v-form>
            </v-col>
          </v-row>
        </template>
        <v-row class="pa-0 ma-0 d-inline">
          <v-btn icon @click.prevent="like" v-if="!noLike">
            <v-icon v-if="likeado" color="primary">mdi-heart</v-icon>
            <v-icon v-else>mdi-heart-outline</v-icon>
          </v-btn>
          <v-btn @click="showDenuncias" v-if="!noFlag" icon>
            <v-icon v-if="!denunciado" color="error lighten-1"
              >mdi-flag-outline</v-icon
            >
            <v-icon v-else color="error lighten-1">mdi-flag</v-icon>
          </v-btn>
          <v-dialog v-model="denuncias.shown" v-if="!noFlag" max-width="500">
            <v-card>
              <v-card-title>Selecciona el motivo de la denuncia</v-card-title>
              <v-card-subtitle>(Puedes seleccionar más de uno)</v-card-subtitle>
              <v-divider></v-divider>
              <v-form @submit.prevent="denunciar" class="pa-4">
                <v-checkbox v-model="denuncias.seleccionadas" value="Es dañíno o ilegal">
                  <template v-slot:label>
                    <v-row no-gutters align="start">
                      <span>Es dañíno o ilegal&nbsp;&nbsp;</span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on="on" v-bind="attrs" small
                            >mdi-help-circle</v-icon
                          >
                        </template>
                        <v-card>
                          <v-list>
                            <v-list-item
                              v-for="(m, i) in denuncias.messages1"
                              :key="i"
                            >
                              <v-list-item-content>{{ m }}</v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-tooltip>
                    </v-row>
                  </template> </v-checkbox
                ><v-checkbox v-model="denuncias.seleccionadas" value="Contiene información personal">
                  <template v-slot:label>
                    <v-row no-gutters align="start">
                      <span>Contiene información personal&nbsp;&nbsp;</span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on="on" v-bind="attrs" small
                            >mdi-help-circle</v-icon
                          >
                        </template>
                        <v-card>
                          <v-list>
                            <v-list-item
                              v-for="(m, i) in denuncias.messages2"
                              :key="i"
                            >
                              <v-list-item-content>{{ m }}</v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-tooltip>
                    </v-row>
                  </template>
                </v-checkbox>
                <v-checkbox
                  v-model="denuncias.seleccionadas"
                  value="Se refiere a otro lugar"
                  label="Se refiere a otro lugar"
                ></v-checkbox>
                <v-text-field
                  dense
                  label="Otro (especifique)"
                  filled
                  color="primary"
                  v-model="denuncias.otro"
                  :rules="[denuncias.rules.max, denuncias.rules.min]"
                  counter="100"
                ></v-text-field>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="cancelarDenuncia"
                    >Cancelar</v-btn
                  >
                  <v-btn type="submit" color="primary">Confirmar</v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "redaxios";
import { req, min, max } from "@/utils/validationRules.js";

export default {
  props: {
    comment: String,
    id_usuario: Number,
    id_comentario: Number,
    id_lugar: Number,
    avatar_src: String,
    nombre: String,
    lnombre: String,
    fecha: String,
    denunciado: { type: Boolean, default: false },
    isReply: { type: Boolean, default: false },
    img_src: String,
    liked: { type: Boolean, default: false },
    noLike: { type: Boolean, default: false },
    noFlag: { type: Boolean, default: false },
    noLink: { type: Boolean, default: false },
    noReplyControls: { type: Boolean, default: false },
    controls: { type: Boolean, default: false },
  },
  data() {
    return {
      img_big: false,
      reply: {
        text: "",
        shown: false,
        rules: {
          req: req,
        },
      },
      likeado: this.liked,
      denuncias: {
        shown: false,
        denunciando: false,
        seleccionadas: [],
        otro: "",
        messages1: [
          "Discurso de odio o discriminacion",
          "Terrorismo",
          "Amenazas o expresiones violentas",
          "Obscenidad",
          "Difamacion",
        ],
        messages2: ["Tales como números de teléfono o direcciones"],
        rules: {
          max: (v) => max(v, 100),
          min: (v) => min(v, 15),
        },
      },
    };
  },
  computed: {
    avatar() {
      let r;
      try {
        r = `/static/avatar/${this.avatar_src}`;
      } catch {
        r = `/static/avatar/img_avatar.png`;
      }
      return r;
    },
    imagen() {
      let r;
      try {
        r = `/static/comentarios/${this.img_src}`;
      } catch {
        r =
          "https://www.cowgirlcontractcleaning.com/wp-content/uploads/sites/360/2018/05/placeholder-img-4.jpg";
      }
      return r;
    },
  },
  methods: {
    cancelReply() {
      this.reply.text = "";
      this.reply.shown = false;
    },
    sendReply() {
      if (
        this.$refs.reply.validate() &&
        this.reply.text != null &&
        this.reply.text !== ""
      ) {
        let formData = new FormData();
        formData.append("direccion_imagen", null);
        formData.append("token", this.$store.getters.getToken);
        formData.append("id_lugar", this.id_lugar);
        formData.append(
          "comentario",
          this.reply.text != "" ? this.reply.text : ""
        );
        formData.append("id_parent", this.id_comentario);

        axios({
          method: "POST",
          url: `${process.env.VUE_APP_API_URL}/comentarios.php`,
          data: formData,
        })
          .then(() => {
            this.$emit("replied");
            this.cancelReply();
            this.$root.$emit("snack", "Se ha enviado tu respuesta");
          })
          .catch((err) => {
            console.error(err);
            this.$root.$emit(
              "snack",
              "No se ha podido enviar la respuesta, vuelve a intentarlo más tarde"
            );
          });
      }
    },
    like() {
      if (this.$store.getters.getToken === null) {
        this.$root.$emit(
          "snack",
          "Tienes que iniciar sesión para dar me gusta"
        );
        return;
      }

      let formData = new FormData();
      formData.append("id_comentario", this.id_comentario);
      formData.append("token", this.$store.getters.getToken);
      formData.append("accion", this.likeado ? "borrar" : "agregar");

      axios({
        method: "post",
        url: `${process.env.VUE_APP_API_URL}/like.php`,
        data: {
          id_comentario: this.id_comentario,
          token: this.$store.getters.getToken,
          accion: this.likeado ? "borrar" : "agregar",
        },
      })
        .then(() => {
          this.likeado = !this.likeado;
        })
        .catch(() => {
          this.$root.$emit(
            "snack",
            "No se ha podido dar me gusta, vuelve a intentarlo más tarde"
          );
        });
    },
    deleteComment() {
      if (confirm("¿Estás seguro de que quieres borrar este comentario?")) {
        console.log("Work in Progress");
      }
    },
    showDenuncias() {
      if (this.denunciado) return;
      if (this.$store.getters.isLoggedIn) this.denuncias.shown = true;
      else
        this.$root.$emit(
          "snack",
          "Tienes que iniciar sesión para denunciar un comentario"
        );
    },
    denunciar() {
      if (this.denuncias.seleccionadas.length == 0 && !this.denuncias.otro) {
        this.$root.$emit("snack", "Tienes que aportar al menos un motivo");
        return;
      }
      if (this.denuncias.denunciando == true) return;
      this.denuncias.denunciando = true;
      let formData = new FormData();
      formData.append("token", this.$store.getters.getToken);
      formData.append("id_comentario", this.id_comentario);
      formData.append("id_denunciante", this.$store.getters.getUserId);
      if (this.denuncias.otro == "")
        formData.append(
          "motivos",
          [...this.denuncias.seleccionadas, this.denuncias.otro].join(";").slice(0,-1)
        );
      else formData.append("motivos", this.denuncias.seleccionadas.join(";").slice(0,-1));
      axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/comentarios.php`,
        data: formData,
      }).then(() => {
        this.denuncias.shown = false;
        this.$root.$emit("snack", "Gracias por notificarnos de este problema");
        this.$emit("updateComments");
        this.denuncias.denunciando = false;
      });
    },
    cancelarDenuncia() {
      this.denuncias.seleccionadas = [];
      this.denuncias.otro = "";
      this.denuncias.shown = false;
    },
  },
};
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.isReply {
  max-width: 95%;
  margin-left: auto;
  margin-right: 0;
  padding-top: 0;
}

.v-tooltip__content {
  padding: 0 !important;
}

.overflow_visible {
  overflow: visible;
}
</style>